import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import { IoGameController } from "react-icons/io5";
import AuthContext from "context/Authcontext";
import {
  capitalizeFirstLetter,
  splitUnderscoreWithCaps,
  changeDateFormatWithTimeZone,
} from "lib/helper";

const Details = ({ activeActivity, onClose }) => {
  const [activity, setActivity] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [propertiesData, setPropertiesData] = useState(null);
  const { profileDetails } = useContext(AuthContext);

  const location = useLocation();
  const timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (activeActivity && activeActivity !== null) {
      const {id, causer_id, full_name, causer_type, ...rest } = activeActivity;
      const formattedActivity = { id, user_id:causer_id, full_name, role:causer_type, ...rest };
      const objectEntries = Object.entries(formattedActivity);
      setActivity(objectEntries);

      if (
        activeActivity.properties !== null &&
        Object.keys(JSON.parse(activeActivity.properties))?.length > 0
      ) {
        setPropertiesData(activeActivity.properties);
      }

      if (
        activeActivity.request_body !== null &&
        Object.keys(JSON.parse(activeActivity.request_body))?.length > 0
      ) {
        setRequestData(activeActivity.request_body);
      }

      if (activeActivity.response_body !== null) {
        setResponseData(activeActivity.response_body);
      }
    }
  }, [activeActivity]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ height: "100%" }}>
          <MDBox p={3}>
            <MDBox
              lineHeight={0}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h5">View Activity</MDTypography>
              <MDButton color={"dark"} variant="text" onClick={() => onClose()}>
                <Icon>arrow_back</Icon>&nbsp;Back
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox p={3}>
            <Grid container>
              {activity &&
                activity?.map(([key, value], index) => {
                     let formattedKey = splitUnderscoreWithCaps(key);
                     if(key == "created_at" || key == "updated_at"){
                        value = changeDateFormatWithTimeZone(value, timeZone);
                     }
                   return (
                    <>
                    {key !== "properties" && key !== "request_body" && key !== "response_body" && (
                      <Grid item xs={12} key={index} mb={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            <MDBox
                              ml={"auto"}
                              sx={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <MDTypography variant="h6">{formattedKey}</MDTypography>
                              <MDTypography variant="h6">:</MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={8}>
                            <MDBox ml={2}>
                              <MDTypography variant="h6">{value ? value : "N/A"}</MDTypography>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {key == "properties" && (
                      <>
                        <Grid item xs={12} key={key} mb={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                                <MDTypography variant="h6">{formattedKey}</MDTypography>
                                <MDTypography variant="h6">:</MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={8}>
                              <MDBox ml={2} className="detail-text">
                                <MDTypography variant="h6" className="detail-text">
                                  {propertiesData ? propertiesData : "N/A"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {key == "request_body" && (
                      <>
                        <Grid item xs={12} key={key} mb={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                                <MDTypography variant="h6">{formattedKey}</MDTypography>
                                <MDTypography variant="h6">:</MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={8}>
                              <MDBox ml={2} className="detail-text">
                                <MDTypography variant="h6" className="detail-text">
                                  {requestData ? requestData : "N/A"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {key == "response_body" && (
                      <>
                        <Grid item xs={12} key={key} mb={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                                <MDTypography variant="h6">{formattedKey}</MDTypography>
                                <MDTypography variant="h6">:</MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={8}>
                              <MDBox ml={2} className="detail-text">
                                <MDTypography variant="h6" className="detail-text">
                                  {responseData ? responseData : "N/A"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                   )
                }
                )}
            </Grid>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Details;
