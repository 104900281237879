import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import API_ENDPOINTS from "apiConfig";
import TableContext from "context/Tablecontext";
import List from "layouts/pages/activityLog/components/List"

function UserActivityLog({userId}){

  const [refresh, setRefresh] = useState(true);
  const [activityList, setActivityList] = useState([]);
  const { verifyToken, profileDetails } = useContext(AuthContext);
  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const [filteringParams, setFilteringParams] = useState([]);

  const getActivityList = async () => {
    let query = `causer_id=${userId}`;
    query += `page=${currentPage}&limit=${pageSize}`;

    if (filteringParams?.length > 0) {
      const filterText = filteringParams
        .filter((params) => params?.value?.length > 0)
        .map((params) => `${params.type}=${params.value}`);
      query += `&${filterText.join("&")}`;
    }

    if (search) {
      query += `&search=${search}`;
    }

    if (orderBy && order) {
      let sortOrder = order === "asc" ? "ASC" : "DESC";
      query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
    }

    try {
      let response = await fetch(API_ENDPOINTS.activityLog + `?${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      let jsonResponse = await response.json();
      if (response.ok) {
        const { data } = jsonResponse;
        setActivityList(data.items);
        const startIndex = (currentPage - 1) * pageSize + 1;
        const endIndex = Math.min(currentPage * pageSize, data.totalItems);
        setEnteries({
          ...enteries,
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          totalMediaCount: data.totalPages,
          enteriesStart: startIndex,
          enteriesEnd: endIndex,
        });
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const addDataInFilteringParams = () => {
    let filterData = localStorage.getItem("activityLogFilteringParamsUser");
    if (filterData === null) {
      setFilteringParams([]);
    } else {
      filterData = JSON.parse(filterData);
      if (typeof filterData === "object" && filterData.length > 0) {
        setFilteringParams(filterData);
      }
    }
  };

  const addFiteringParamsToStorage = () => {
    if (filteringParams?.length > 0) {
      localStorage.setItem("activityLogFilteringParamsUser", JSON.stringify(filteringParams));
    } else {
      localStorage.setItem("activityLogFilteringParamsUser", JSON.stringify([]));
    }
  };

  const handleStopRefresh = () => {
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh && userId) {
      initializeTable();
      addDataInFilteringParams();
      getActivityList();
      handleStopRefresh();
    }
  }, [refresh, userId]);

  useEffect(() => {
    if (!refresh) {
      getActivityList();
    }
  }, [pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if (!refresh) {
        getActivityList();
      }
    }, 500);
    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (!refresh && filteringParams) {
      addFiteringParamsToStorage();
      getActivityList();
    }
  }, [filteringParams]);

    
    return (
        <List activityList={activityList} filteringParams={filteringParams} setFilteringParams={setFilteringParams} />
    );
}

export default UserActivityLog;