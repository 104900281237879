const roles = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Affiliate",
      value: "affiliate",
    },
    {
      label: "User",
      value: "user",
    },
    {
      label: "Distributor",
      value: "distributor",
    },
  ];

  const subscriptionStatus = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
    {
      label: "Trial",
      value: "trial",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Trial finished",
      value: "trial_finished",
    },
  ];

  const subscriptionPlan = [
     {
      label: "Trial",
      value: "withoutCardTrial",
     },
     {
      label: "Monthly",
      value: "monthly",
     },
     {
      label: "Yearly",
      value: "yearly",
     },
     {
      label: "Lifetime",
      value: "lifetime",
     },
     {
      label: "Appsumo",
      value: "appsumo",
     },
  ];

  export {
    roles,
    subscriptionStatus,
    subscriptionPlan
  }