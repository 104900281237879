/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import { useContext, useState, useEffect } from "react";
import AuthContext from "context/Authcontext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import API_ENDPOINTS from "apiConfig";
import toast from "react-hot-toast";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { formatDateTimeZone } from "lib/helper";

const moment = require("moment");

dayjs.extend(utc);

function BasicInfo() {
  const { profileDetails, updateUserDetails, updateProfilePicture, getProfileDetails } =
    useContext(AuthContext);

  const maxDate = moment().format("YYYY-MM-DD");

  const [refresh, setRefresh] = useState(true);
  const [updateUserData, setUpdateUserData] = useState({
    full_name: "",
    title: "",
    phone: "",
    gender: "",
    location: "",
    address: "",
    description: "",
    facebook_link: "",
    instagram_link: "",
    twitter_link: "",
    linkedin_link: "",
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonthDate, setSelectedMonthDate] = useState(null);
  const [birthdate, setBirthDate] = useState(null);

  const onUserUpdateChange = (event) => {
    const { name, value } = event.target;
    setUpdateUserData({
      ...updateUserData,
      [name]: value,
    });
  };

  const handleDateSelection = (value) => {
    const selectedDob = dayjs(value).utc();
    setBirthDate(selectedDob);
  };

  const onUpdateProfile = async () => {
    try {
      setRefresh(false);
      let message;
      if (!(updateUserData.full_name && updateUserData.gender)) {
        if (!updateUserData.full_name) {
          message = "Full name is required";
        } else if (!updateUserData.gender) {
          message = "Gender is required";
        }
        throw new Error(message);
      }

      if (selectedYear || selectedMonthDate) {
        if (!selectedYear) {
          throw new Error("Select year before selecting month and date for your birthdate");
        }

        if (!selectedMonthDate) {
          throw new Error("Select month and date for your birthdate");
        }
      }

      if (updateUserData.phone) {
        if (updateUserData.phone.toString().length != 10) {
          throw new Error("Phone number should be 10 digits");
        } else if (updateUserData.phone.toString().startsWith("0")) {
          throw new Error("Phone number should not start with 0");
        }
      }

      if (updateUserData.facebook_link) {
        if (!updateUserData.facebook_link.includes("facebook")) {
          throw new Error("Facebook link is not valid");
        }
      }
      if (updateUserData.instagram_link) {
        if (!updateUserData.instagram_link.includes("instagram")) {
          throw new Error("Instagram link is not valid");
        }
      }
      if (updateUserData.twitter_link) {
        if (!updateUserData.twitter_link.includes("twitter")) {
          throw new Error("Twitter link is not valid");
        }
      }
      if (updateUserData.linkedin_link) {
        if (!updateUserData.linkedin_link.includes("linkedin")) {
          throw new Error("Linkedin link is not valid");
        }
      }

      let dob = null;
      if (birthdate) {
        const year = birthdate.format("YYYY");
        const month = birthdate.format("MM");
        const day = birthdate.format("DD");
        dob = `${year}-${month}-${day}`;
      }

      const response = await updateUserDetails({ ...updateUserData, birthdate: dob });
      if (response?.ok) {
        setRefresh(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const initializeValues = () => {
    setUpdateUserData({
      full_name: profileDetails.full_name ? profileDetails.full_name : "",
      title: profileDetails.title ? profileDetails.title : "",
      phone: profileDetails.phone ? profileDetails.phone : "",
      gender: profileDetails.gender ? profileDetails.gender : "",
      location: profileDetails.location ? profileDetails.location : "",
      address: profileDetails.address ? profileDetails.address : "",
      description: profileDetails.description ? profileDetails.description : "",
      facebook_link: profileDetails.facebook_link ? profileDetails.facebook_link : "",
      instagram_link: profileDetails.instagram_link ? profileDetails.instagram_link : "",
      twitter_link: profileDetails.twitter_link ? profileDetails.twitter_link : "",
      linkedin_link: profileDetails.linkedin_link ? profileDetails.linkedin_link : "",
    });
  };

  useEffect(() => {
    if (refresh) {
      getProfileDetails();
    }
  }, [refresh]);

  useEffect(() => {
    if (profileDetails) {
      initializeValues();
    }
    if (profileDetails?.birthdate) {
      let dob = moment(profileDetails.birthdate).format("YYYY-MM-DD");
      const dobUTC = dayjs.utc(dob);
      setBirthDate(dobUTC);
    }
  }, [profileDetails]);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.full_name}
              name="full_name"
              label="Name"
              placeholder="Enter Full Name"
              onChange={onUserUpdateChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.title}
              name="title"
              label="Title"
              placeholder="Enter title"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={6}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  label="Birthdate"
                  value={birthdate}
                  openTo="year"
                  onChange={(value) => handleDateSelection(value)}
                  maxDate={dayjs(maxDate).utc()}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputLabelProps: {
                        shrink: true,
                      },
                      sx: { width: "99%" },
                    },
                  }}
                />
              </DemoContainer>
            </Grid>
          </LocalizationProvider>

          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.phone}
              name="phone"
              label="Phone Number"
              placeholder="Enter phone number"
              inputProps={{ type: "number" }}
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.location}
              name="location"
              label="Your location"
              placeholder="Enter your location"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ color: "#7b809a", fontSize: "12px", fontWeight: "normal" }}
                required
              >
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                value={updateUserData.gender}
                onChange={onUserUpdateChange}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={<span style={{ color: "#000", fontWeight: "normal" }}>Male</span>}
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={<span style={{ color: "#000", fontWeight: "normal" }}>Female</span>}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={<span style={{ color: "#000", fontWeight: "normal" }}>Other</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.address}
              name="address"
              label="Address"
              placeholder="Enter your address"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.description}
              name="description"
              label="Description"
              placeholder="Enter description"
              onChange={onUserUpdateChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.facebook_link}
              name="facebook_link"
              label="Facebook"
              placeholder="Facebook Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.instagram_link}
              name="instagram_link"
              label="Instagram"
              placeholder="Instagram Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.twitter_link}
              name="twitter_link"
              label="Twitter"
              placeholder="Twitter Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.linkedin_link}
              name="linkedin_link"
              label="LinkedIn"
              placeholder="LinkedIn Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="profiePic"
              id="fileInput"
              label="Profile Picture"
              inputProps={{ type: "file" }}
              onChange={updateProfilePicture}
            />
          </Grid>
        </Grid>
        <Grid display="flex" justifyContent="end" mt={4}>
          <MDBox ml="auto" mt={2}>
            <MDButton variant="gradient" color="dark" size="small" onClick={onUpdateProfile}>
              Update Profile
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
