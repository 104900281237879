import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Avatar from 'react-avatar';
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import { useNavigate } from "react-router-dom";

function Header({ profileImage, name, title, tabValue, setTabValue  }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const navigate = useNavigate();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MDBox position="relative">
        <Grid container spacing={3} alignItems="center">
          <Grid item>
          <Avatar
              name={name}
              src={profileImage}  
              round={true}
              size="77"  
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {title}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={1}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab sx={{ textAlign: "center" }}
                  label="Profile Information"
                />
                <Tab
                  label="Activity Log"
                />
              </Tabs>
            </AppBar>

            <MDBox ml={1}>
            <MDButton 
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={(e) => navigate(-1)}
                    
                  >
                    Back
                  </MDButton>
            </MDBox>
            </MDBox>
          </Grid>
        </Grid>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
