import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_ENDPOINTS from "apiConfig";
import PropTypes from "prop-types";

import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";

import AuthContext from "context/Authcontext";
import { useMaterialUIController } from "context";
import GlobalStateContext from "context/GlobalStateContext";
import TableContext from "context/Tablecontext";
import Tooltip from "@mui/material/Tooltip";
import MDTable from "components/MDTables";
import DefaultCell from "components/Cell/DefaultCell";
import Header from "../Details/Header";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultInfoCard from "./DefaultInfoCard";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Divider from "@mui/material/Divider";
import UserActivityLog from "../Details/UserActivityLog";

import {
  capitalizeFirstLetter,
  splitUnderscoreWithCaps,
  changeDateFormatWithTimeZone,
} from "lib/helper";

const PaymentHistory = ({ userId }) => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const { profileDetails } = useContext(AuthContext);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const [canSearch, setCanSearch] = useState(true);
  const [filteringParams, setFilteringParams] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const loadData = (paymentDetails) => {
    if (paymentDetails && paymentDetails.length > 0) {
      let columns = [
        {
          Header: "id",
          accessor: "id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "subscription_id",
          accessor: "subscription_id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "stripe_event_id",
          accessor: "stripe_event_id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "stripe_invoice_id",
          accessor: "stripe_invoice_id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "status",
          accessor: "status",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "created at",
          accessor: "created_at",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
      ];

      let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      let rows = paymentDetails.map((detail) => {
        const created_at = detail?.created_at
          ? changeDateFormatWithTimeZone(detail?.created_at, timeZone)
          : "N/A";
        return {
          id: detail.id.toString(),
          subscription_id: detail.subscription_id || "N/A",
          stripe_event_id: detail.stripe_event_id || "N/A",
          stripe_invoice_id: detail.stripe_invoice_id || "N/A",
          status: detail.status || "N/A",
          created_at: created_at || "N/A",
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getPaymentHistory = async () => {
    try {
      let query = "";
      query += `page=${currentPage}&limit=${pageSize}`;

      if (search) {
        query += `&search=${search}`;
      }

      if (orderBy && order) {
        let sortOrder = order === "asc" ? "ASC" : "DESC";
        query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
      }

      const response = await fetch(API_ENDPOINTS.getPaymentHistory + `/${userId}?${query}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const jsonResponse = await response.json();
      if (response.ok) {
        setPaymentHistory(jsonResponse.data.items);
        loadData(jsonResponse.data.items);
      } else {
        setPaymentHistory([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStopRefresh = () => {
    setRefresh(false);
  };

  useEffect(() => {
    if (userId && refresh) {
      initializeTable();
      getPaymentHistory();
      handleStopRefresh();
    }
  }, [userId]);

  useEffect(() => {
    if (!refresh) {
      getPaymentHistory();
    }
  }, [pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if (!refresh) {
        getPaymentHistory();
      }
    }, 500);
    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  return <MDTable table={dataTable} canSearch={canSearch} />;
};

const Details = () => {
  const {
    showUserEditComp,
    setShowUserEditComp,
    editedUser,
    setEditedUser,
    userDetail,
    setUserDetail,
  } = useContext(GlobalStateContext);
  const [modifiedUserData, setModifiedUserData] = useState(null);
  const { profileDetails, breadCrumbsText, setBreadCrumbsText } = useContext(AuthContext);
  const [profileDetailInfo, setProfileDetailInfo] = useState({});
  const [subscriptionDetailInfo, setSubscriptionDetailInfo] = useState({});
  const [calendarInfo, setCalendarInfo] = useState({});
  const [userSettingInfo, setUserSettingInfo] = useState({});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [profileImage, setProfileImage] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const navigation = useNavigate();

  const handleEditClicked = () => {
    setShowUserEditComp(true);
    setEditedUser(coupon);
    navigation("/pages/users/user-list");
  };

  const handleModifyUserData = (data) => {
    let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (Object.keys(data).length > 0) {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          let value = data[key];

          switch (key) {
            case "gender":
              data[key] = capitalizeFirstLetter(value);
              break;
            case "full_name":
              data[key] = capitalizeFirstLetter(value);
              break;
            case "duration":
              data[key] = capitalizeFirstLetter(value);
              break;

            case "discount_type":
              data[key] = splitUnderscoreWithCaps(value);
              break;

            case "cta_status":
              data[key] = value === 1 ? "Active" : "Inactive";
              break;

            case "role_name":
              data[key] = capitalizeFirstLetter(value);
              break;

            case "is_verified":
              if (value === "true") {
                data[key] = "Yes";
              } else {
                data[key] = "No";
              }
              break;

            case "subscription_status":
              data[key] = capitalizeFirstLetter(value);
              break;

            case "subscription_plan":
              data[key] = capitalizeFirstLetter(value);
              break;

            case "redemptions_count":
              if (!value) {
                data[key] = 0;
              }
              break;
            
              case "birthdate":
                if(value) {
                  const date = new Date(value);
                  const formattedDate = changeDateFormatWithTimeZone(date, timeZone).split(' ')[0];
                  data[key] = formattedDate;
                }
                break;

            case "last_activity":
            case "valid_till":
            case "created_at":
            case "updated_at":
              if (value) {
                const date = new Date(value);
                data[key] = changeDateFormatWithTimeZone(date, timeZone);
              }
              break;
            default:
              break;
          }
        }
      }
    }

    setProfileDetailInfo({
      fullName: data.full_name || "",
      title: data.title || "",
      email: data.email || "",
      birthdate: data.birthdate || "",
      gender: data.gender || "",
      phone: data.phone || "",
      location: data.location || "",
      address: data.address || "",
    });

    setUserSettingInfo({
      role: data.role_name || "",
      isVerified: data.is_verified || "",
      createdAt: data.created_at || "",
      lastActivity: data.last_activity || "",
    });

    setSubscriptionDetailInfo({
      subscriptionPlan: data.subscription_plan || "",
      ...(data.role_id == 2
        ? {
            affiliateOnboardingStatus: data.affiliate_onboarding_status || "",
            affiliateOnboardingStatus: data.affiliate_onboarding_status || "",
          }
        : {
            stripeCouponCode: data.stripe_coupon_code || "",
            subscriptionStatus: data.subscription_status || "",
          }),
    });

    setCalendarInfo({
      timezone: data.time_zone || "",
      calendarInfo: data.calendar_info || "",
      calendarUrl: data.calendar_url || "",
      ctaText: data.cta_text || "",
      ctaStatus: data.cta_status || "",
      microsoftCalendarId: data.microsoft_calendar_id || "",
    });

    setModifiedUserData(data);
  };

  const getUserDetail = async (id) => {
    try {
      const response = await fetch(API_ENDPOINTS.getUserById + `${id}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const jsonResponse = await response.json();
      if (response.ok) {
        setUserDetail(jsonResponse.data);
        handleModifyUserData(jsonResponse.data);

        let imagePath = null;
        if (jsonResponse?.data?.image_path?.startsWith("http")) {
          imagePath = jsonResponse.data.image_path;
        } else if (!jsonResponse?.data?.image_path?.startsWith("publics")) {
          imagePath = process.env.REACT_APP_BACKEND_URL + "/" + jsonResponse.data.image_path;
        }

        setProfileImage(imagePath);
      } else {
        setUserDetail(null);
        setProfileImage(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { id } = useParams();
  useEffect(() => {
    if (id && !userDetail) {
      getUserDetail(id);
    } else if (userDetail) {
      let imagePath = null;
      if (userDetail?.image_path?.startsWith("http")) {
        imagePath = userDetail.image_path;
      } else if (!userDetail?.image_path?.startsWith("publics")) {
        imagePath = process.env.REACT_APP_BACKEND_URL + "/" + userDetail.image_path;
      }

      setProfileImage(imagePath);
      handleModifyUserData(userDetail);
    }
  }, [id, userDetail]);

  useEffect(() => {}, [modifiedUserData]);
  return (
    <BaseLayout>
      <Card id="user-details">
        <MDBox p={2}>
          {userDetail && (
            <>
              <Header
                profileImage={profileImage}
                name={userDetail?.full_name}
                title={userDetail?.title}
                tabValue={tabValue}
                setTabValue={setTabValue}
              />
              {tabValue === 0 ? (
                <>
                  <MDBox mt={5} mb={3}>
                    <Grid container>
                      <Grid item xs={6} lg={6}>
                        <Grid container>
                          <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                            <ProfileInfoCard
                              title="profile information"
                              description={userDetail.description || "N/A"}
                              info={profileDetailInfo}
                              divider={false}
                              social={[
                                {
                                  link: userDetail.facebook_link,
                                  icon: <FacebookIcon />,
                                  color: "facebook",
                                },
                                {
                                  link: userDetail.twitter_link,
                                  icon: <TwitterIcon />,
                                  color: "twitter",
                                },
                                {
                                  link: userDetail.instagram_link,
                                  icon: <InstagramIcon />,
                                  color: "instagram",
                                },
                                {
                                  link: userDetail.linkedin_link,
                                  icon: <LinkedInIcon />,
                                  color: "linkedin",
                                },
                              ]}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              shadow={false}
                            />
                          </Grid>

                          <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                            <DefaultInfoCard
                              title="Subscription information"
                              info={subscriptionDetailInfo}
                              shadow={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Grid container>
                          <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                            <DefaultInfoCard
                              title="Setting information"
                              info={userSettingInfo}
                              shadow={false}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                            <DefaultInfoCard
                              title="Calendar information"
                              info={calendarInfo}
                              shadow={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mt={2}>
                  <MDBox mx={2}>
                  <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          Payment History Information
        </MDTypography>
        </MDBox>
                    <PaymentHistory userId={userDetail?.id} />
                  </MDBox>
                </>
              ) : (
                <MDBox mt={2}>
                  <UserActivityLog userId={userDetail?.id} />
                </MDBox>
              )}
            </>
          )}
        </MDBox>
      </Card>
    </BaseLayout>
  );
};

export default Details;
