import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const ToggleContainerButton = ({ isToggle, onToggle }) => {
  return (
    <MDBox mr={2}>
      <MDButton variant="gradient" color="info" size="small" onClick={onToggle}>
        {isToggle ? "Reset password with OTP" : "Reset Password with old Password"}
      </MDButton>
    </MDBox>
  );
};

export default ToggleContainerButton;
